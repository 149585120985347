<template>
  <div class="reportList_container">
    <top style="padding: 0.2rem 0"></top>
    <div class="reportList_search_content">
      <div class="reportList_search">
        <div class="reportList_search_block">
          <div class="reportList_search_block_key">区域</div>
          <div class="reportList_search_block_value">
            <el-select
              size="mini"
              popper-class="selectOption"
              filterable
              v-model="search.area"
              @change="changeValue('area')"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="val in config.areaList"
                :key="val"
                :label="val.label"
                :value="val.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="reportList_search_block">
          <div class="reportList_search_block_key">RMM</div>
          <div class="reportList_search_block_value">
            <el-select
              popper-class="selectOption"
              filterable
              v-model="search.RMM"
              placeholder="请选择"
              @change="changeValue('RMM')"
            >
              <el-option label="全部" :value="Number(0)"></el-option>
              <el-option
                v-for="val in config.RMMList"
                :key="val"
                :label="val.label"
                :value="val.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="reportList_search_block">
          <div class="reportList_search_block_key">RSM</div>
          <div class="reportList_search_block_value">
            <el-select
              popper-class="selectOption"
              filterable
              v-model="search.RSM"
              placeholder="请选择"
              @change="changeValue('RSM')"
            >
              <el-option label="全部" :value="Number(0)"></el-option>
              <el-option
                v-for="val in config.RSMList"
                :key="val"
                :label="val.label"
                :value="val.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="reportList_search">
        <div class="reportList_search_block">
          <div class="reportList_search_block_key">DSM</div>
          <div class="reportList_search_block_value">
            <el-select
              popper-class="selectOption"
              filterable
              v-model="search.DSM"
              placeholder="请选择"
            >
              <el-option
                label="全部"
                :value="Number(0)"
                @change="changeValue('DSM')"
              ></el-option>
              <el-option
                v-for="val in config.DSMList"
                :key="val"
                :label="val.label"
                :value="val.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="reportList_search_block">
          <div class="reportList_search_block_key">姓名</div>
          <div class="reportList_search_block_value">
            <el-input
              v-model.trim="search.name"
              placeholder="请输入姓名"
            ></el-input>
          </div>
        </div>
        <div class="reportList_search_block">
          <div class="reportList_search_block_key">手机</div>
          <div class="reportList_search_block_value">
            <el-input
              v-model.trim="search.phone"
              placeholder="请输入手机号"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="reportList_search">
        <div class="reportList_search_block">
          <div class="reportList_search_block_key">审核状态</div>
          <div class="reportList_search_block_value">
            <el-select
              popper-class="selectOption"
              filterable
              v-model="search.status"
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(val, index) in config.auditStatus"
                :key="val"
                :label="val"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <el-button type="primary" @click="loadData">查询</el-button>
        <el-button type="primary" @click="goToStatistics"
          >区域报名统计</el-button
        >
        <input
          type="file"
          v-if="resetFile"
          v-show="false"
          ref="selectFile"
          @change="changeFile"
        />
      </div>
    </div>
    <div class="reportList_table">
      <div class="report-table-thead">
        <div class="report-one report-table-row report-table-row-border">
          序号
        </div>
        <div class="report-three report-table-row report-table-row-border">
          医生姓名
        </div>
        <div class="report-four report-table-row report-table-row-border">
          医生电话
        </div>
        <div class="report-four report-table-row report-table-row-border">
          医院名称
        </div>
        <div class="report-three report-table-row report-table-row-border">
          医助姓名
        </div>
        <div class="report-two report-table-row report-table-row-border">
          主席权益
        </div>
        <div class="report-two report-table-row report-table-row-border">
          讲者权益
        </div>
        <div class="report-two report-table-row">讨论权益</div>
      </div>
      <ListScroll
        :url="$urls.hospital.list"
        :select="search"
        ref="ListScroll"
        @receiveResponse="receiveResponse"
      >
        <template v-slot:listItem="{ cItems, cIndex }">
          <div
            :style="{ background: cIndex % 2 == 0 ? '#f0f9fc' : '#fff' }"
            class="report-table-tr"
          >
            <div class="report-one report_number">
              {{ cIndex + 1 }}
            </div>
            <div style="flex: 20 0 0; display: flex; flex-direction: column">
              <div class="report-info">
                <div class="report-three report-table-row">
                  <div class="report-table-value">
                    {{ cItems.name }}
                  </div>
                </div>
                <div class="report-four report-table-row">
                  <div class="report-table-value">
                    {{ cItems.phone }}
                  </div>
                </div>
                <div class="report-four report-table-row">
                  <div class="report-table-value">
                    {{ cItems.hospital }}
                  </div>
                </div>
                <div class="report-three report-table-row">
                  <div class="report-table-value">
                    {{ cItems.sale_name }}
                  </div>
                </div>
                <div class="report-two report-table-row">
                  <div class="report-table-value">
                    {{ cItems.cohosts_count }}
                  </div>
                </div>
                <div class="report-two report-table-row">
                  <div class="report-table-value">
                    {{ cItems.speaker_count }}
                  </div>
                </div>
                <div class="report-two report-table-row">
                  <div class="report-table-value">
                    {{ cItems.guest_count }}
                  </div>
                </div>
              </div>
              <div class="audit_reason" v-if="cItems.audit_status == 20">
                审核不通过：{{ cItems.audit_reason }}
              </div>
              <div style="display: flex">
                <div class="report-three report-table-row">
                  <div class="report-table-button_group">
                    <el-button
                      type="primary"
                      size="small"
                      style="background: #e6a23c"
                      @click="goToSignUpInfo(cItems, 1)"
                      v-if="cItems.audit_status == 0"
                      >审核</el-button
                    >
                  </div>
                </div>
                <div class="report-four report-table-row">
                  <div class="report-table-button_group">
                    <el-button
                      type="primary"
                      size="small"
                      style="background: #1e649e"
                      @click="goToSignUpInfo(cItems, 2)"
                      >修改信息</el-button
                    >
                  </div>
                </div>
                <div class="report-four report-table-row">
                  <div class="report-table-button_group">
                    <el-button
                      type="primary"
                      size="small"
                      style="background: #1e649e"
                      @click="doUpdateEquity(cItems)"
                      >修改权益次数</el-button
                    >
                  </div>
                </div>
                <div class="report-three report-table-row">
                  <div class="report-table-button_group">
                    <el-button
                      type="danger"
                      @click="deleteInfo(cItems)"
                      size="small"
                      >删除</el-button
                    >
                  </div>
                </div>
                <div class="report-two report-table-row">
                  <div class="report-table-button_group"></div>
                </div>
                <div class="report-two report-table-row">
                  <div class="report-table-button_group"></div>
                </div>
                <div class="report-two report-table-row">
                  <div class="report-table-button_group"></div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </ListScroll>
    </div>
  </div>
</template>

<script>
import PopopInstance from "@/components/unit/PopupInstance";
import ListScroll from "../../unit/ListScroll.vue";
import top from "./top.vue";
export default {
  name: "reportList",
  data() {
    return {
      resetFile: true,
      config: {
        fields: {},
        areaList: [
          // "东1",
          // "东2",
          // "东3",
          // "南区",
          // "西1",
          // "西2",
          // "西X",
          // "北1",
          // "北2",
        ],
        auditStatus: {
          //审核状态
          0: "待审核",
          10: "审核通过 ",
          20: "审核未通过",
        },
        RMMList: [],
        RSMList: [],
        DSMList: [],
      },
      search: {
        RMM: 0,
        RSM: 0,
        DSM: 0,
        area: "",
        name: "",
        phone: "",
        status: "",
        page: 1,
        pagesize: 20,
      },
      data: [
        {
          name: "于磊",
          phone: "13108081212",
          hospital: "山西省肿瘤医院",
          sale_name: "周周",
          cohosts_count: "1",
          guest_count: "1",
          speaker_count: "1",
        },
      ],
      page: { totalPage: 0 },
      loading: null,
    };
  },
  components: {
    ListScroll,
    top,
  },
  mounted() {
    this.loading = this.$tips.loading();
    this.getAreaList();
    document.getElementsByTagName("title")[0].innerHTML = "报名系统";
  },
  methods: {
    loadData() {
      this.loading = this.$tips.loading();
      this.$refs.ListScroll && this.$refs.ListScroll.searchData(this.search);
    },
    receiveResponse(response) {
      this.page.totalPage = response.count;
      this.loading.close();
    },
    goToStatistics() {
      let Elloading = this.$tips.loading();
      let url = this.$tools.getURL(
        this.$urls.hospital.exportAreaList,
        this.search
      );
      this.$axios
        .get(url)
        .then((res) => {
          Elloading.close();
          this.$tips.success({ text: "导出成功" });
          console.log("res.data :>> ", res.data);
          window.location.href = res.data;
          // window.open(res.data);
        })
        .catch(() => {
          Elloading.close();
          this.$tips.error({ text: "导出出错" });
        });
    },
    getAreaList() {
      this.$axios
        .get(this.$urls.hospital.areaList)
        .then((res) => {
          this.config.areaList = res.data.map((v) => {
            return { label: v.area };
          });
          this.getRMM(0, "RMM");
        })
        .catch(() => {});
    },
    changeValue(key) {
      switch (key) {
        case "area":
          this.getRMM(0, "RMM");
          break;
        case "RMM":
          this.getRMM(this.search[key], "RSM");
          break;
        case "RSM":
          this.getRMM(this.search[key], "DSM");
          break;
        case "province":
          this.changeProvince();
          break;
        case "city":
          this.changeCity();
          break;
        case "hospital":
          this.changeHospital();
          break;
      }
    },
    //获取AMM RSM DSM
    getRMM(e, key, type = false) {
      if (!type) {
        this.search.DSM = 0;
        this.config.DSMList = {};
        if (!type && key == "RMM") {
          this.search.RMM = 0;
          this.search.RSM = 0;
          this.config.RMMList = {};
          this.config.RSMList = {};
        }
        if (!type && key == "RSM") {
          this.search.RSM = 0;
          this.config.RSMList = {};
        }
      }
      if (!this.search.area) return;
      let url = this.$tools.getURL(this.$urls.hospital.managerList, {
        id: e,
        area: this.search.area,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.config[`${key}List`] = res.data.map((v) => {
            return { id: v.id, label: v.name };
          });
        })
        .catch(() => {});
    },
    deleteInfo(item) {
      console.log("item :>> ", item);
      let msg = {
        title: "警告",
        html: `<div class="popup-content-main">删除后数据将无法恢复，请谨慎操作。请再次确认是否删除当前项？</div>`,
        btngroup: { sure: "确认" },
        align: "center",
      };
      PopopInstance(msg).then(() => {
        this.deleteCallback(item);
      });
    },
    deleteCallback(data) {
      let loading = this.$tips.loading();
      let url = this.$tools.getURL(this.$urls.hospital.delete, {
        id: data.id,
      });

      this.$axios
        .delete(url)
        .then(() => {
          if (
            this.page.totalPage % this.search.pagesize == 1 &&
            this.search.page > 1
          ) {
            this.search.page -= 1;
          }
          this.loadData();
          setTimeout(() => {
            loading.close();
            this.$tips.success({ text: "删除成功" });
          }, 1000);
        })
        .catch(() => {
          setTimeout(() => {
            loading.close();
          }, 1000);
        });
    },
    goToSignUpInfo(item, type) {
      //type 1 审核 2 修改
      this.$router.push({
        path: "/signUpInfo",
        query: { type, id: item.id },
      });
    },
    doUpdateEquity(item) {
      this.$router.push({
        path: "/equity",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style >
.reportList_container {
  width: 100%;
  height: 100%;
  padding: 0 0.8125rem;
  font-size: 0.625rem;
  display: flex;
  flex-direction: column;
}
.reportList_search_content {
  padding-bottom: 1.25rem;
}

.reportList_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}
.reportList_search:last-child {
  margin-bottom: 0;
}
.reportList_search_block {
  display: flex;
  align-items: center;
  padding: 5px 0;
  position: relative;
}
.reportList_search_block_key {
  white-space: nowrap;
  margin-right: 0.625rem;
}
.reportList_search_block_value {
  width: 5.3125rem;
  height: 1.25rem;
}

.reportList_search .el-input {
  display: flex;
}
.reportList_search .el-input__inner {
  width: 2.3125rem !important;
  height: 1.25rem !important;
  font-size: 0.625rem !important;
  padding: 0.1rem !important;
}
.reportList_search .el-select__input {
  width: 2vw !important;
}
.reportList_search .el-select .el-input__suffix .el-input__suffix-inner {
  line-height: inherit;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-button {
  height: 18px !important;
  min-height: 18px !important;
  padding: 0 29px !important;
  font-size: 0.625rem !important;
  background: #1e649e;
  border: none;
  border-radius: 0.125rem !important;
}
.reportList_table {
  flex: 1 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.report-table-thead {
  display: flex;
  background: #1e649e;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  height: 1.75rem;
  border-radius: 5px 5px 0 0;
  border: 0.1px solid #ccc;
  border-bottom: none;
}
.report-table-row-border {
  border-right: 0.1px solid #fff;
}
.report-table-row {
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.report-one {
  flex: 1 0 0;
}
.report-two {
  flex: 1.5 0 0;
}
.report-three {
  flex: 3 0 0;
}
.report-four {
  flex: 4 0 0;
}
.report-tablet-body {
}
.report-table-tr {
  display: flex;
  /* height: 4.375rem; */
  border: 0.1px solid #ccc;
  position: relative;
}
.report-table-tr:last-child {
}
.report-table-value {
  flex: 1 0 0;
  width: 100%;
  height: 14px;
  /* padding: 0.2px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-table-button_group {
  width: 100%;
  padding: 0.25px 0;
  height: 20px;
}
.report-table-tr .el-button {
  padding: 0.125rem 6px !important;
  min-height: 14px !important;
  font-size: 0.625rem !important;
  border: none;
  border-radius: 0.125rem !important;
}
.reportList_container .listScroll_main {
  justify-content: start !important;
}

.selectOption .el-select-dropdown__item.selected {
  color: #1e649e !important;
}
.reportList_container .el-select .el-input.is-focus .el-input__wrapper,
.reportList_container .el-input__wrapper:hover {
  box-shadow: 0 0 0 1px #1e649e inset !important;
}
/* .reportList_container .el-select .el-input.is-focus .el-input__inner {
  border-color: #1e649e !important;
}
.reportList_container .el-input__inner:focus {
  border-color: #1e649e !important;
} */
.audit_reason {
  display: flex;
  color: red;
  padding: 0.2rem 0.2rem 0.2rem 1rem;
  word-break: break-all;
}
.report-info {
  height: 3.125rem;
  display: flex;
}
.report_number {
  border-right: 0.1px solid rgb(204, 204, 204);
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportList_container .el-button:hover,
.el-button:focus {
  background: #1e649e !important;
}
</style>